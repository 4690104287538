import { Box, Flex, Text, Image, Link, VStack, Heading, useColorMode } from '@chakra-ui/react'
import LogoWhite from '../logos/nautil_logo_white.webp'
import LogoBlack from '../logos/nautil_logo_black.png'

function Footer() {
	const { colorMode } = useColorMode()
	const textColor = colorMode === 'light' ? 'black' : 'white'

	return (
		<Box as='footer' bg={colorMode === 'light' ? 'white' : 'gray.900'} color={textColor} p={8} boxShadow='md'>
			<Flex maxW='6xl' mx='auto' flexWrap='wrap' gap={10} justifyContent='space-between'>
				{/* Logo */}
				<VStack align='flex-start'>
					{colorMode === 'dark' ? (
						<Image src={LogoWhite} alt='nautil logo firmy' height='60px' />
					) : (
						<Image src={LogoBlack} alt='nautil logo firmy' height='60px' />
					)}
				</VStack>

				{/* Informacje o firmie */}
				<VStack align='flex-start' spacing={1}>
					<Heading size='md' color={textColor}>
						INFORMACJE O FIRMIE
					</Heading>
					<Text>Nautil Sp. z o.o.</Text>
					<Text>NIP: 5871738680</Text>
					<Text>REGON: 524096454</Text>
					<Text>KRS: 0001010773</Text>
					<Text>E-mail: biuro@nautil.pl</Text>
					<Text>Tel: 507 140 847</Text>
					<Text>Tel: 530 262 005</Text>
				</VStack>

				{/* Odnośniki */}
				<VStack align='flex-start' spacing={1}>
					<Heading size='md' color={textColor}>
						ODNOŚNIKI
					</Heading>
					<Link href='http://nautil.pl' isExternal _hover={{ textDecoration: 'underline' }}>
						Strona firmowa
					</Link>
					<Link href='https://nautil.pl/audyt-wcag/' isExternal _hover={{ textDecoration: 'underline' }}>
						Audyt WCAG
					</Link>
					<Link href='https://nautil.pl/audytarchitektoniczny/' isExternal _hover={{ textDecoration: 'underline' }}>
						Audyt architektoniczny
					</Link>
					<Link href='https://nautil.pl/#ofertat' isExternal _hover={{ textDecoration: 'underline' }}>
						Więcej projektów
					</Link>
				</VStack>

				{/* Kontakt */}
				<VStack align='flex-start' spacing={1}>
					<Heading size='md' color={textColor}>
						KONTAKT
					</Heading>
					<Link href='/contact' _hover={{ textDecoration: 'underline' }}>
						Kontakt
					</Link>
				</VStack>
			</Flex>

			{/* Prawa autorskie */}
			<Box textAlign='center' mt={10}>
				<Text>© 2023 Nautil Sp. z o.o.</Text>
				<Link href='/privatePolicy' _hover={{ textDecoration: 'underline' }} mt={1}>
					Polityka prywatności
				</Link>
			</Box>
		</Box>
	)
}

export default Footer
