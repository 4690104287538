import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
	field: {
		// Styl pola
	},
	element: {
		color: 'red.500', // Zmieniasz kolor ikony kalendarza
	},
})

export const inputTheme = defineMultiStyleConfig({ baseStyle })
