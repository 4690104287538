import { useColorMode, Box, Text, Image } from '@chakra-ui/react'
import LogoWhite from '../logos/nautil_logo_white.webp'
import LogoBlack from '../logos/nautil_logo_black.png'

const Logo = () => {
	const { colorMode } = useColorMode()

	return (
		<Box as='a' href='https://nautil.pl' display='flex' alignItems='center'>
			{colorMode === 'dark' ? (
				<Image src={LogoWhite} alt='nautil logo firmy' height='60px' />
			) : (
				<Image src={LogoBlack} alt='nautil logo firmy' height='60px' />
			)}
			<Text ml={2} mt={2} fontWeight='semibold' fontSize='2xl' color={colorMode === 'dark' ? 'white' : '#252C32'}>
				Generator Certyfikatów
			</Text>
		</Box>
	)
}

export default Logo
