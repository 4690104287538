import { Box, Flex, IconButton, useColorMode, Heading } from '@chakra-ui/react'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'
import Logo from './Logo'

function Header() {
	const { colorMode, toggleColorMode } = useColorMode()

	return (
		<Box
			as='header'
			position='sticky'
			top='0'
			zIndex='50'
			bg={colorMode === 'light' ? 'white' : 'gray.900'}
			boxShadow='lg'
			w='100%'>
			<Flex maxW='7xl' mx='auto' py={4} px={6} alignItems='center' justifyContent='space-between'>
				<Heading size='lg' color={colorMode === 'light' ? 'gray.800' : 'white'}>
					<Logo />
				</Heading>
				<Flex alignItems='center'>
					{/* Link Cennik */}
					{/* <Link
						href='/pricing'
						color={colorMode === 'light' ? 'black' : 'white'}
						mr={4}
						_hover={{ textDecoration: 'underline' }}>
						Cennik
					</Link> */}
					{/* Przełącznik trybu ciemnego/jasnego */}
					<IconButton
						aria-label='Toggle dark mode'
						icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
						onClick={toggleColorMode}
						mr={4}
					/>
					{/* Przycisk Zaloguj się */}
					{/* <Button colorScheme='teal' variant='solid' onClick={() => alert('Logowanie...')}>
						Zaloguj się
					</Button> */}
				</Flex>
			</Flex>
		</Box>
	)
}

export default Header
